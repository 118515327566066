.flexContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.map {
  flex: 0 0 65%;
  align-self: auto;
  /* background-color: #dddfd4; */
  background-color: #E5DEDA;
  text-align: right;
}

.text {
  flex: 1 0 33%;
  align-self: auto;
  padding: 0 1rem 1rem 1rem;
  background-color: #f7f6f4;
}

@media (min-width: 55em) {
  .flexContainer {
    flex-direction: row;
  }
  .text {
    margin: 4rem 0.7rem 0;
  }
}
