.date {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0 0 0.75rem 0;
}

.date .value {
  flex: 1 0 auto;
  padding: 0 0.3rem;
  text-align: right;
  font-size: 1.2rem;
  color: #000000;
}

.date .button {
  appearance: none;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 0.8rem;
  color: #24292E;
  background-color: #e5deda;
  cursor: pointer;
  font-size: 0.9rem;
  list-style: none;
  padding: 0.3rem;
  -webkit-appearance: initial;
  min-width: 6rem;
}

.date .value:nth-child(2n) {
  text-align: left;
}

@media (min-width: 55em) {
  .date .value {
    padding: 0 1rem;
  }
}
