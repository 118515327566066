.map {
  max-height: 100vh;
}

.energyPriceLevels {
  position: absolute;
  top: 1rem;
  left: 2rem;
}


@media (min-width: 55em) {
  .energyPriceLevels {
    top: 3.5rem;
    left: 2rem;
  }
}