html,
body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

body {
  font-family: latoRegular, sans-serif;
  font-size: 1rem;
  background-color: #eff4ff;
  color: #5f6367;
  line-height: 1.4;
}

h1,
h2 {
  color: #000000;
}

b {
  font-family: latoBold, sans-serif;
}

@font-face {
  font-family: latoRegular;
  src: url(./fonts/Lato-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: latoBold;
  src: url(./fonts/Lato-Bold.ttf);
  font-display: swap;
}

@font-face {
  font-family: loraBoldItalic;
  src: url(./fonts/Lora-BoldItalic.ttf);
  font-display: swap;
}
