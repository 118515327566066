.header {
  width: 100%;
  height: 4rem;
  line-height: 1;
}

.header .flexContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.menuIsOpen {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #eff4ff;
}

.logo {
  font-size: 2rem;
  font-family: loraBoldItalic, sans-serif;
  color: #000000;
  /* color: #8a5574; */
  font-weight: bold;
  padding: 0.8rem 1rem 0;
  flex: 0 0 65%;
  align-self: auto;
}

.header .logo a,
.header .logo a:hover,
.header .logo a:active,
.header .logo a.active {
  text-decoration: none;
  color: #000000;
}


.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.header .flexContainer ul {
  height: 100%;
  display: none;
  justify-content: center;
}

.header .flexContainer ul li {
  font-size: 1.2rem;
  padding: 1.3rem 1rem 0;
}

.header .flexContainer ul :first-child {
  padding-left: 0;
}

.header .flexContainer ul :last-child {
  padding-right: 0;
}

.menuIsOpen ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 5rem);
}

.menuIsOpen ul li {
  font-size: 1.6rem;
  margin: 1rem 0;
}

.header a {
  text-decoration: none;
  color: #173e43;
}

.header a:hover,
.header a:active,
.header a.active {
  color: #848484;
}

.menuIcon {
  flex: 0 1 auto;
  cursor: pointer;
  display: inline-block;
  padding: 1.9rem 1.3rem;
  position: relative;
  user-select: none;
  border: none;
  margin: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
}

.menuIcon .navIcon {
  background: #333;
  display: block;
  position: relative;
  height: 0.14rem;
  width: 1.2rem;
}

.menuIcon .navIcon:before,
.menuIcon .navIcon:after {
  background: #333;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.menuIcon .navIcon:before {
  top: 0.35rem;
}

.menuIcon .navIcon:after {
  top: -0.35rem;
}

.menuIsOpen .navIcon {
  background: transparent;
}

.menuIsOpen .navIcon:before {
  transform: rotate(-45deg);
  top: 0;
}

.menuIsOpen .navIcon:after {
  transform: rotate(45deg);
  top: 0;
}

@media (min-width: 55em) {
  .headerMapPage {
    position: absolute;
    z-index: 1;
  }
  .header nav {
    flex: 1 0 auto;
    align-self: auto;
  }
  .header .flexContainer ul {
    display: flex;
  }
  .menuIcon {
    display: none;
  }
  .menuIsOpen .menuIcon {
    display: inline-block;
  }
}
