section .description {
  font-size: 0.9rem;
}

.actionContainer {
  display: flex;
  flex-direction: column;
}

.actionContainer .vatSelector {
  flex: 1 0 auto;
  align-self: auto;
  margin-bottom: 1rem;
  text-align: center;
}

.actionContainer .dateSelector {
  flex: 1 0 auto;
  align-self: auto;
}

.priceContainer {
  display: flex;
  flex-direction: column;
}

.priceContainer .header {
  font-family: latoBold, sans-serif;
  font-weight: bold;
  padding: 1rem 0;
  background-color: #E8D8D2;
}

.countryContainer {
  background-color: #E5DEDA;
  padding-bottom: 0.15rem;
}

.countryContainer:last-child {
  padding-bottom: 0.5rem;
}

.countryContainer .hr {
  border: 0.02rem solid #c5bab5;
  margin: 0.3rem 0.75rem;
}

.countryContainer .hiddenHr {
  border: 0;
  margin: 0.2rem 0;
}

.country {
  display: flex;
  flex: 0 0 auto;
  align-self: auto;
  padding: 0.25rem 0rem 0.25rem 0.2rem;
  color: #173e43;
  font-size: 0.9rem;
}

.countryWithBZs {
  padding: 0;
  padding-bottom: 0.35rem;
}

.country .name {
  flex: 0 0 50%;
  white-space: nowrap;
}
.priceContainer .header .name {
  padding-left: 1rem;
}

.country .nameWithBZs {
  margin-left: 1.8rem;
  padding-bottom: 0;
}

.country .name .dot {
  position: relative;
  top: 0.1rem;
  height: 0.75rem;
  width: 0.75rem;
  margin: 0 0.5rem;
  border-radius: 50%;
  display: inline-block;
}

.country .name .dotWithBZs {
  margin-left: 3rem;
  top: 0.05rem;
  height: 0.7rem;
  width: 0.7rem;
}

.country .name .trivial {
  background-color: #82e4b5;
}

.country .name .low {
  background-color: #75bb94;
}

.country .name .medium {
  background-color: #fbcd62;
}

.country .name .concerning {
  background-color: #fbb879;
}

.country .name .high {
  background-color: #8a5574;
}

.country .price {
  flex: 0 0 30%;
  text-align: left;
}

.country .vat {
  flex: 0 0 20%;
  text-align: left;
}

.country a {
  color: #173e43;
}

.country a:hover,
.country a:active,
.country a.active {
  color: #848484;
}

@media (min-width: 78em) {
  .actionContainer {
    flex-direction: row;
  }
  .actionContainer .vatSelector {
    margin-bottom: 0;
  }
}
