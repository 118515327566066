.label {
  padding-right: 0.5rem;
  font-weight: bold;
}

.timeZone {
  appearance: auto;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 0.8rem;
  color: #24292E;
  background-color: #e5deda;
  cursor: pointer;
  font-size: 0.75rem;
  list-style: none;
  padding: 0.3rem 1.2rem 0.3rem 0.4rem;
  -webkit-appearance: initial;

  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position:
    calc(100% - 0.7rem) calc(0.6rem),
    calc(100% - 0.3rem) calc(0.6rem);
  background-size:
    0.4rem 0.4rem,
    0.4rem 0.4rem;
  background-repeat: no-repeat;
}

@media (min-width: 55em) {
  .timeZone {
    font-size: 0.9rem;
  }
}
