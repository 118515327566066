.flexContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.map {
  flex: 0 0 38%;
  align-self: auto;
  padding: 0 0.5rem 0;
  text-align: right;
}

.details {
  flex: 1 0 60%;
  align-self: auto;
  padding: 0 0.5rem 0;
  margin: 0 0.5rem 0;
  background-color: #f7f6f4;
}

.details .description {
  padding-bottom: 1rem;
}

.details .description .note {
  padding: 1rem 0;
}

.details .description .info {
  padding: 1rem 0;
}


.details .description .oneBz {
  display: inline;
}

.details .description .manyBzs {
  font-size: 0.8rem;
  padding: 0.1rem 0 0 1rem;
  display: block;
}

.actionContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.actionContainer .timeZone {
  flex: 1 0 auto;
  align-self: auto;
  margin-bottom: 1rem;
  text-align: center;
}

.actionContainer .vatSelector {
  flex: 1 0 auto;
  align-self: auto;
  margin-bottom: 1rem;
  text-align: center;
}

.actionContainer .dateSelector {
  flex: 1 0 auto;
  align-self: auto;
}

.details .chartBox {
  background-color: #f4eeee;
  padding: 0.5rem;
  margin-bottom: 2.5rem;
}

@media (max-width: 55em) {
  .details .chartBox {
    height: 20rem;
  }
}

@media (min-width: 55em) {
  .flexContainer {
    flex-direction: row;
  }
  .details {
    padding: 0 2rem 0;
  }
  .details .chartBox {
    padding: 1rem;
    aspect-ratio: 2 / 1;
  }
}

@media (min-width: 70em) {
  .actionContainer .timeZone,
  .actionContainer .vatSelector {
    margin-bottom: 0;
  }
}
