.map {
  max-height: calc(100vh - 5rem);
  /* background-color: #dddfd4; */
  background-color: #E5DEDA;
}

.energyPriceLevels {
  position: absolute;
  top: 0.75rem;
  left: 1.25rem;
}
