.label {
  padding: 0 0.5rem;
  font-weight: bold;
  vertical-align: middle;
}

.switch {
  position: relative;
  display: inline-block;
  width: 3.75rem;
  height: 1.8rem;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 1rem;
  border: 1px solid rgba(27, 31, 35, 0.15);
}

.slider:before,
.slider:after {
  position: absolute;
  content: "";
  height: 1.29rem;
  width: 1.29rem;
  left: 0.18rem;
  top: 0.142rem;
  background-color: #5f6367;
  transition: opacity .2s ease-in, transform .4s;
  border-radius: 50%;
  border: 1px solid rgba(27, 31, 35, 0.15);
}

.slider::after {
  opacity: 0;
}

input:checked + .slider {
  background-color: #e5deda;
}

input:checked + .slider:before,
input:checked + .slider::after {
  transform: translateX(1.85rem);
}

input:checked + .slider::after {
  opacity: 1;

  background:
    linear-gradient(135deg, transparent 0%, transparent 40%,#fff 0%,#fff 55%,transparent 57%,transparent 100%),
    linear-gradient(45deg, transparent 0%, transparent 45%,#fff 0%,#fff 75%,transparent 57%,transparent 100%);
  background-position:
    calc(100% - 0.26rem) calc(0.45rem),
    calc(100% - 0.76rem) calc(0.65rem);
  background-size:
    0.5rem 0.5rem,
    0.3rem 0.3rem;
  background-repeat: no-repeat;
}
